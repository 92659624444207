<template>
  <div>
    <div class="search-container">
      <el-input
        placeholder="请输入查询的关键字"
        v-model="searchVal"
        maxlength="20"
        clearable>
      </el-input>
      <el-button type="primary" style="margin-left: 20px" @click="startSearch">查询</el-button>
    </div>
    
    <div class="message-item" v-for="item in list" :key="item._id">
      <div class="head">
        <img :src="item.headImg" alt="">
        <span>{{ item.userName }}</span>
      </div>
      <div class="content">留言：{{ item.content }}</div>
      <div class="reply">
        <div class="me">
          <img :src="require('@/assets/image/me.png')" alt="">
          <span>作者</span>
        </div>
        <div class="reply-text">
          <span v-if="item.replay">回复: {{ item.replay}}</span>
          <span v-else>暂未回复</span>
        </div>
      </div>
      <el-divider></el-divider>
    </div>

    <div class="footer">
      <el-pagination
        layout="prev, pager, next"
        :page-size="5"
        :current-page.sync="page"
        @current-change="startSearch"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getMessageUrl } from '@/assets/js/api'
import { Input, Button, Pagination, Divider } from 'element-ui'
export default {
  data() {
    return {
      list: [],
      searchVal: '',
      total: 0,
      page: 1
    }
  },
  components: {
    'el-input': Input,
    'el-button': Button,
    'el-pagination': Pagination,
    'el-divider': Divider
  },
  mounted() {
    this.startSearch()
  },
  methods: {
    startSearch() {
      this.$axios.post(getMessageUrl, {
        page: this.page,
        pageSize: 5,
        searchKey: this.searchVal
      }).then(res => {
        this.list = res.data.data
        this.total = res.data.num
      })
    }
  }
}
</script>

<style lang="less" scoped>
.search-container {
  width: 80vw;
  max-width: 800px;
  margin: 20px auto;
  display: flex;
}

.message-item {
  // border: 1px solid #e5e5e5;
  width: 80vw;
  max-width: 800px;
  margin: 0 auto;
  border-radius: 6px;

  .head {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }

  .content {
    margin: 10px;
    padding-left: 30px;
  }

  .reply {
    margin-left: 70px;

    .me {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
      }

      span {
        padding-left: 15px;
      }
    }

    .reply-text {
      padding-top: 20px;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
}
</style>